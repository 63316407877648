import Send from '../../cp.client.js'

export default {
    list (param) {
        return Send({
            url: '/bsa/bsaTotalData/list',
            method: 'get',
            params: {
                yearWeek: param.yearWeek,
                porCtr: param.porCtr,
                porPlc: param.porPlc,
                dlyCtr: param.dlyCtr,
                dlyPlc: param.dlyPlc,
                salEnt: param.salEnt,
                salUno: param.salUno,
                bkgShipper: param.bkgShipper
            }
        })
    }
}
